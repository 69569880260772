<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Bulk Charge</h3>
            </div>
            <div class="card-body">
              <div class="row1">
                <card shadow type="secondary">
                  <form>
                    <div class="pl-lg-4">
                      <div class="row">
                        <div class="col-md-12">
                          <base-input
                            alternative=""
                            label="Name"
                            input-classes="form-control-alternative"
                            placeholder="Name"
                            :value="model.bulk_name"
                            @input="
                              (event) => (model.bulk_name = event.target.value)
                            "
                          />
                          <div
                            class="error mb-2"
                            v-if="v$.model.bulk_name.$error"
                          >
                            Field is required.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Amount($)"
                            input-classes="form-control-alternative"
                            placeholder="Amount($)"
                            :value="amount"
                            @input="
                              (event) =>
                                (model.bulk_amount = event.target.value)
                            "
                          />
                          <div
                            class="error mb-2"
                            v-if="v$.model.bulk_amount.$error"
                          >
                            Valid Amount required.
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <base-input
                            input-classes="form-control-alternative"
                            label="Date"
                            placeholder="Date"
                          >
                            <flat-picker
                              @on-open="focus"
                              @on-close="blur"
                              :config="{ altFormat: 'd/m/Y', altInput: true }"
                              class="form-control datepicker"
                              v-model="model.bulk_date"
                            >
                            </flat-picker>
                          </base-input>
                          <div
                            class="error mb-2"
                            v-if="v$.model.bulk_date.$error"
                          >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <base-input label="Type">
                            <el-select
                              style="width: 100%"
                              v-model="model.bulk_type"
                              :value="model.bulk_type"
                              placeholder=""
                              @change="onType(model.bulk_type)"
                            >
                              <el-option
                                v-for="option in type"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <div
                            class="error mb-2"
                            v-if="v$.model.bulk_type.$error"
                          >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <base-input label="Membership" v-if="membership">
                            <el-select
                              style="width: 100%"
                              v-model="model.bulk_type_name"
                              placeholder=""
                              :value="model.bulk_type_name"
                              filterable
                              @change="onMembershipType($event)"
                            >
                              <el-option
                                v-for="option in membershipData"
                                :value="option.id"
                                :label="option.membership_name"
                                :key="option.membership_name"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <base-input label="Subscription" v-if="subscription">
                            <el-select
                              style="width: 100%"
                              v-model="model.bulk_type_name"
                              placeholder=""
                              :value="model.bulk_type_name"
                              filterable
                              @change="onMembershipType($event)"
                            >
                              <el-option
                                v-for="option in subscriptionData"
                                :value="option.id"
                                :label="option.subscription_name"
                                :key="option.subscription_name"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <base-input label="Charge" v-if="charge">
                            <el-select
                              style="width: 100%"
                              v-model="model.bulk_type_name"
                              placeholder=""
                              :value="model.bulk_type_name"
                              filterable
                              @change="onMembershipType($event)"
                            >
                              <el-option
                                v-for="option in chargeData"
                                :value="option.id"
                                :label="option.charge_name"
                                :key="option.charge_name"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <div
                            class="error mb-2"
                            v-if="v$.model.bulk_type_name.$error"
                          >
                            Field is required.
                          </div>
                        </div>

                        <!-- <div class="col-lg-6">
                        <base-input
                          alternative=""
                          label="Actions"
                          input-classes="form-control-alternative"
                        />
                      </div> -->
                      </div>
                      <div class="row1">
                        <base-input
                          input-classes="form-control-alternative"
                          label="Note"
                          :value="model.bulk_note"
                        >
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Note"
                            v-model="model.bulk_note"
                          ></textarea>
                        </base-input>
                      </div>
                    </div>

                    <div class="row1">
                      <div class="table-responsive">
                        <base-table
                          class="table align-items-center table-flush"
                          :class="type === 'dark' ? 'table-dark' : ''"
                          :thead-classes="
                            type === 'dark' ? 'thead-dark' : 'thead-light'
                          "
                          :key="index"
                          tbody-classes="list"
                          :data="rowData"
                        >
                          <template v-slot:columns>
                            <th>Member</th>
                            <th>Comment</th>
                            <th>
                              <i
                                class="ni ni-fat-add text-green"
                                style="font-size: large"
                                v-on:click="addRow()"
                              >
                              </i>
                            </th>
                          </template>

                          <template v-slot:default="row">
                            <td>
                              <base-input
                                alternative=""
                                input-classes="form-control-alternative"
                              >
                                <el-select
                                  input-classes="form-control-alternative"
                                  placeholder=""
                                  v-model="row.item.bulk_member"
                                >
                                  <el-option
                                    v-for="option in memberData"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="option.full_name"
                                    :key="option.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </td>
                            <td>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Note"
                                :value="
                                  (row.item.bulk_comment = model.bulk_note)
                                "
                              >
                                <textarea
                                  class="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                  placeholder=""
                                  v-model="row.item.bulk_comment"
                                ></textarea>
                              </base-input>
                            </td>
                            <td>
                              <i
                                class="ni ni-fat-delete text-red"
                                style="font-size: large"
                                v-on:click="deleteRow(index)"
                              >
                              </i>
                            </td>
                          </template>
                        </base-table>
                      </div>
                    </div>
                    <div></div>
                    <div class="row" v-if="id.data == 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="create()"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="id.data != 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="Update()"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                  <br />
                  <div v-if="isLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Created
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                  <div v-if="isUpdateLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Updated
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ElSelect, ElOption } from "element-plus";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const bulkRepository = RepositoryFactory.get("bulk");
const memberShipRepository = RepositoryFactory.get("membership");
const subscriptionRepository = RepositoryFactory.get("subscription");
const chargesRepository = RepositoryFactory.get("chargesRepo");
const memberRepository = RepositoryFactory.get("member");
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
export default {
  name: "BulkCharge",
  components: {
    flatPicker,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  validations() {
    return {
      model: {
        bulk_name: { required },
        bulk_amount: { required, numeric },
        bulk_type_name: { required },
        bulk_date: { required },
        bulk_type: { required },
      },
    };
  },
  data() {
    return {
      v$: useVuelidate(),
      id: {},
      isLoading: false,
      isUpdateLoading: false,
      amount: "",
      subscriptionData: [],
      membership: false,
      subscription: false,
      charge: false,
      membershipData: [],
      rowData: [{}],
      tableData: [],
      chargeData: [],
      memberData: [],
      model: {},
      selects: {
        simple: "",
        languages: [{ value: "Bahasa Indonesia", label: "Bahasa Indonesia" }],
      },
      type: [
        { value: "Membership", label: "Membership" },
        { value: "Subscription", label: "Subscription" },
        { value: "Charge", label: "Charge" },
      ],
    };
  },
  methods: {
    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      if (this.amount) {
        this.model.bulk_amount = this.amount;
      }
      this.model.bulkdata = JSON.stringify(this.rowData);
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await bulkRepository.create(data);

      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/bulkcharge/view_bulkcharge");
        }, 1000);
      }
    },
    async Update() {
      delete this.model.edit;
      if (this.amount) {
        this.model.bulk_amount = this.amount;
      }
      this.model.bulkdata = this.rowData;
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await bulkRepository.create(data);
      if (response.data.status == 101 || response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/bulkcharge/view_bulkcharge");
        }, 1000);
      }
    },

    addRow() {
      this.rowData.push({});
    },
    deleteRow(i) {
      this.rowData.splice(i, 1);
    },
    getMembershipList() {
      memberShipRepository.getMembership().then((data) => {
        this.tableData = Object.freeze(data);
        this.membershipData = this.tableData.data;
      });
    },
    getChargesList() {
      chargesRepository.getCharges().then((data) => {
        this.tableData = Object.freeze(data);
        this.chargeData = this.tableData.data;
      });
    },
    getSubscriptionList() {
      subscriptionRepository.getSubscription().then((data) => {
        this.tableData = Object.freeze(data);
        this.subscriptionData = this.tableData.data;
      });
    },
    getMemberList() {
      memberRepository.getMember().then((data) => {
        this.tableData = Object.freeze(data);
        this.memberData = this.tableData.data;
      });
    },
    onType(value) {
      if (value === "Membership") {
        this.subscription = false;
        this.membership = true;
        this.charge = false;
      } else if (value === "Subscription") {
        this.subscription = true;
        this.membership = false;
        this.charge = false;
      } else if (value === "Charge") {
        this.subscription = false;
        this.membership = false;
        this.charge = true;
      } else {
        this.subscription = false;
        this.membership = false;
        this.charge = false;
      }
    },
    onMembershipType(value) {
      if (this.membership == true) {
        var data = this.membershipData.filter((x) => x.id === value);
        if (data) {
          this.amount = data[0].membership_charge;
        }
      }

      if (this.subscription == true) {
        var data1 = this.subscriptionData.filter((x) => x.id === value);
        if (data1) {
          this.amount = data1[0].subscription_charge;
        }
      }

      if (this.charge == true) {
        var data2 = this.chargeData.filter((x) => x.id === value);
        if (data2) {
          this.amount = data2[0].charge_amount;
        }
      }
    },
  },
  mounted() {
    this.getSubscriptionList();
    this.getChargesList();
    this.getMembershipList();
    this.getMemberList();
  },
};
</script>

<style></style>
